.leaflet-fade-anim .leaflet-popup {
  transition: none;
}

.leaflet-popup-close-button {
  display: none;
}

.my-popup {
  transition-duration: 0s;
}

.my-popup .leaflet-popup-tip-container .leaflet-popup-tip { pointer-events: none; }
